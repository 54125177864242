import React, { useEffect, useReducer, useState } from "react";
import CategoryFilters from "../components/CategoryFilters";

export default function SearchScreen() {
  return (
    <div>
      <CategoryFilters />
    </div>
  );
}
